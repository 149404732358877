//import axios from 'axios';
import { axios } from '../utils/axios'

import {
  apiUrl,
} from '../utils/axios';

import { getAllCountries, saveCountries, countryLoading, saveCities, getAllCities } from "../reducers/countryReducer";

const logMiddleware = (store) => (next) => (action) => {

    switch (action.type) {
      case 'country/getAllCities':
        // const cities = getAllCitiesByCountryId(action.countryId);
        // axios.get(`${apiUrl}country`)
        //   .then(({ data }) => console.table(data))
        //   .error(e => console.error(e));
        // store.dispatch(saveCities(cities));
        break;

      case getAllCountries().type:
        store.dispatch(countryLoading({ status : false}));
        axios.get(`${apiUrl}/get_all_countries`)
          .then(({ data }) => {
            let countries = data.slice();
            store.dispatch(saveCountries({ countries : countries }));
            store.dispatch(countryLoading({ status : true}));
          })
          .catch((e) => console.error(e));
        break;

      default:
        break;
    }

  next(action);
};

export default logMiddleware;
