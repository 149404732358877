import React from "react";
import './NumberValidation.scss';
import Header from "../../../containers/Header";
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, TextField, Input} from "@material-ui/core";
import { connect } from 'react-redux';
import {numberValidation, changePassword, userUpdate, codeGeneration} from "../../../services/users";
import {setMessage, setOpen, setBackColor} from "../../../reducers/snackbarReducer";
import {saveUser} from "../../../reducers/userReducer";
import {Redirect} from 'react-router-dom';
import {sendAskEmailToUserAfterRegister, sendWelcomeEmailToStoreAfterRegister} from "../../../services/emailjs";

var interval = null;

class NumberValidation extends React.Component {
    constructor(props) {
        super(props);
        this.onHandleTelephoneChange = this.onHandleTelephoneChange.bind(this);
        this.state = {
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            regexp : /^[0-9\b]+$/,
            isAuthenticated: false,
            numberChanged: false,
            passwordChanged: false,
            value: '',
            focused: false,
            showResend: false,
            counter: ""
        }
        this.digit1 = React.createRef();
        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setBackColor = props.setBackColor
        this.saveUser = props.saveUser;
        this.currentCountry = props.currentCountry;
        this.operation = props.operation;
    }

    componentDidMount() {
        this.digit1.current.focus();
        this.startTimer(60);
    }

    onHandleTelephoneChange = e => {
        /*let old = e.target.value;*/
        let keys = [37,39,8];
        let digit = keys.includes(e.keyCode) ? undefined : e.key;
        // if value is not blank, then test the regex
        if (digit === '' || this.state.regexp.test(digit)) {
            this.setState({ [e.target.name]: digit });
            if (digit !== ''){
                if(e.target.closest('div').nextSibling)
                    e.target.closest('div').nextSibling.firstChild.focus();
            }
        }

        if (e.keyCode === 8) {
            this.setState({ [e.target.name]: '' });
            if(e.target.closest('div').previousSibling) {
                e.target.closest('div').previousSibling.firstChild.focus();
            }
        } else if(e.keyCode === 37) {
            if(e.target.closest('div').previousSibling)
                e.target.closest('div').previousSibling.firstChild.focus();
        } else if (e.keyCode === 39) {
            if(e.target.closest('div').nextSibling)
                e.target.closest('div').nextSibling.firstChild.focus();
        }

    };

    submit = () => {
        const {digit1, digit2, digit3, digit4, digit5, digit6} = this.state;
        var params = {
            "user_id" : this.props.preLoginData.user_id.toString(),
            "code" : `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`,
            "phone_indicatif" : this.props.preLoginData.phone_indicatif || this.props.preLoginData.phone_indicatif,
            "phone" : this.props.preLoginData.phone || this.props.preLoginData.phone
           }
            if (digit1 === ''|| digit2 === ''|| digit3 === ''|| digit4 === ''|| digit5 === ''|| digit6 === '') {
                this.setBackColor("red")
                this.setMessage("Veuillez Saisir un numero un code de 6 chiffres");
                this.setOpen(true);
            } else {
                numberValidation(params).then(({data})=> {
                    console.warn('numberValidation: ', data);
                    this.setBackColor("green")
                    if (data.RESPONSECODE === 200) {
                        this.setMessage(data.RESPONSEMESSAGE);
                        if (this.props.operation == 1) {
                            // this.changePassword();
                            this.setState({passwordChanged: true});      
                        
                        } else if (this.props.operation == 2) {
                            this.setMessage('Votre numéro a été bien modifié!');
                            this.saveUser(data.RESPONSEDATA.user_data);
                            this.setState({numberChanged: true});
                        } else {
                            this.setState({isAuthenticated: true});
                            if(this.props.preLoginData?.email){
                                if (this.props.preLoginData?.pro_rs){
                                    sendWelcomeEmailToStoreAfterRegister({
                                        to_email:this.props.preLoginData?.email,
                                        to_name:this.props.preLoginData?.first_name
                                    })
                                    .then((response)=>{ console.log('SEND EMAIL OK', response)})
                                    .catch((error)=>{console.log('SEND EMAIL ERROR', error)})
                                    
                                }
                                else {
                                    sendAskEmailToUserAfterRegister({
                                        to_email:this.props.preLoginData?.email,
                                        to_name:this.props.preLoginData?.first_name
                                    })
                                    .then((response)=>{ console.log('SEND EMAIL OK', response)})
                                    .catch((error)=>{console.log('SEND EMAIL ERROR', error)})
                            }
                            }
                            // this.saveUser(data.RESPONSEDATA.user_data);
                        }
                    } else {
                        this.setBackColor("red")
                        this.setMessage(data.RESPONSEMESSAGE || "Erreur, veuillez réessayer!");
                        
                    }
                }).catch((e)=>{
                    this.setBackColor("red")
                    this.setMessage("Erreur lors de la création");
                }).finally((e)=> {
                });
            }
    };

    resendCode = () => {
        var userDataNumber = {
            user_id: this.props.preLoginData.user_id,
            phone_indicatif : this.props.preLoginData.phone_indicatif,
            phone : this.props.preLoginData.phone
        }
        codeGeneration(userDataNumber).then(({data}) => {
            console.warn("codeGeneration: ", data);
            if (data.RESPONSECODE === 200) {
                this.setBackColor("green")
                this.setMessage("Un nouveau code a été envoyer!");
                this.startTimer(60);
                this.setState({showResend: false});
            } else {
                this.setMessage("Erreur, veuillez réessayer!");
            }
        }).catch(error => {
            // this.setMessage({message : data.RESPONSEMESSAGE});
            this.setBackColor("red")
            this.setOpen(true);
        });
    };

    stopInterval = () => {
        clearInterval(interval);
        this.setState({counter: "01:00", showResend: true});
    };

    startTimer = (duration) => {
        var timer = duration, minutes, seconds;
        interval = setInterval(() => {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
    
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
    
            if (--timer < 0) {
                this.stopInterval();
            }
            this.setState({counter: minutes + ":" + seconds});
        }, 1000);
    };
    /* Input focus next */

    render() {
        const { value, focused  } = this.state;
        const values = value.split("");
        console.log('prelo', this.preLoginData)
        console.log('PRELOAD USER', this.props.preLoginData)

        return (
            <div>
                <Header />
                {
                    this.state.isAuthenticated && <Redirect to='/account-success-message' />
                }
                {
                    this.state.numberChanged && <Redirect to='/dashboard/profile' />
                }
                {
                    this.state.passwordChanged && <Redirect to='/new-password' />
                }
                <div className="number-validation">
                    <div className="number-validation-form">
                        <h1>Vérifiez que vous avez ce téléphone</h1>
                        <p>Saisissez le code de vérification que nous avons envoyé au <br/> <span>+{this.props.preLoginData.phone_indicatif || this.props.preLoginData.phone_indicatif} {this.props.preLoginData.phone || this.props.preLoginData.phone}</span> </p>
                        <form action="">
                            <div className="form-inner">
                                {/*<div className={"form-inner-item " + ((this.state.digit1 !== "") ? "field" : "")}>
                                    <input type="text" ref={this.digit1} name="digit1" maxLength="1" value={this.state.digit1} onChange={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit2 !== "") ? "field" : "")}>
                                    <input type="text" name="digit2" maxLength="1" value={this.state.digit2} onChange={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit3 !== "") ? "field" : "")}>
                                    <input type="text" name="digit3" maxLength="1" value={this.state.digit3} onChange={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit4 !== "") ? "field" : "")}>
                                    <input type="text" name="digit4" maxLength="1" value={this.state.digit4} onChange={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit5 !== "") ? "field" : "")}>
                                    <input type="text" name="digit5" maxLength="1" value={this.state.digit5} onChange={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit6 !== "") ? "field" : "")}>
                                    <input type="text" name="digit6" maxLength="1" value={this.state.digit6} onChange={this.onHandleTelephoneChange}/>
                                </div>*/}
                                
                                <div className={"form-inner-item " + ((this.state.digit1 !== "") ? "field" : "")}>
                                    {/*<TextField variant="standard" ref={this.digit1} name="digit1" maxLength="1" value={this.state.digit1} onKeyUp={this.onHandleTelephoneChange} />*/}
                                    <input type="number" ref={this.digit1} name="digit1" maxLength="1" value={this.state.digit1} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit2 !== "") ? "field" : "")}>
                                    <input type="number" name="digit2" maxLength="1" value={this.state.digit2} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit3 !== "") ? "field" : "")}>
                                    <input type="number" name="digit3" maxLength="1" value={this.state.digit3} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit4 !== "") ? "field" : "")}>
                                    <input type="number" name="digit4" maxLength="1" value={this.state.digit4} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit5 !== "") ? "field" : "")}>
                                    <input type="number" name="digit5" maxLength="1" value={this.state.digit5} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                                <div className={"form-inner-item " + ((this.state.digit6 !== "") ? "field" : "")}>
                                    <input type="number" name="digit6" maxLength="1" value={this.state.digit6} onKeyUp={this.onHandleTelephoneChange}/>
                                </div>
                            </div>
                            <div className="form-instruction">
                                <p>La récupération de votre code de vérification peut prendre quelques minutes</p>
                                {
                                this.state.showResend ? (
                                    <p className="resend-code" onClick={this.resendCode}>Renvoyer le code</p>
                                ) : (
                                    <p>Veuillez patienter <span className="count">{this.state.counter}</span> secondes avant de demander le renvoi d'un nouveau code</p>
                                )}
                            </div>
                            <Button className="validation-button" onClick={this.submit}>Authentifier</Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    preLoginData: state.user.preLoginData,
    currentCountry: state.country.currentCountry,
    operation: state.user.operation,
  });
  
  const mapDispatchToProps = dispatch => ({
    setOpen: (open) => {
        dispatch(setOpen({ open : open}));
    },
    setMessage: (message) => {
        dispatch(setMessage({ message : message}));
    },

    setBackColor: (backColor) => {
        dispatch(setBackColor({backColor: backColor}));
    },

    saveUser: (user) => {
        dispatch(saveUser({ user : user}));
    },
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(NumberValidation);
