import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// import {getToken, onMessageListener} from './firebase';
import stores from './store/index';

/* Global style */
import './global.scss';

import App from './containers/App';
import SnackBarComponent from "./containers/SnackbarComponent";
import Fonts from './fonts';
import * as serviceWorker from './serviceWorker';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

/* Global cookies and app download popup */
import { CookiesPopup } from "./components/Cookies/cookies";
import { AppDialog } from "./components/app-dialog/app-dialog";

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}; // Override console.log
    console.error = () => {};
    console.warn = () => {};
}

ReactDOM.render(
    <Provider store={stores.store}>
        <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
            <PersistGate persistor={stores.persistor}>
                <Router>
                    <SnackBarComponent/>
                    <Fonts/>
                    <App/>
                    <AppDialog/>
                    <CookiesPopup/>
                </Router>
            </PersistGate>
        </ClearBrowserCacheBoundary>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
