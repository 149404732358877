import React, { useState, useEffect, useRef } from 'react';
import fr from 'react-phone-input-2/lang/fr.json';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import {NavLink, Redirect} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {useDispatch, useSelector} from "react-redux";
import {setBackColor, setMessage, setOpen} from "../../reducers/snackbarReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createUser} from "../../services/users";
import {setPreLoginData, changeOperation} from "../../reducers/userReducer";
import { getAllCategories } from '../../services/category';
import ReCAPTCHA from 'react-google-recaptcha'

const togglePassword = ({ currentTarget }) => {
    currentTarget.classList.toggle('active');
    const passwordInput = document.getElementById('password');

    if (currentTarget.classList.contains('active')) {
        passwordInput.type = 'text';
    }
    else {
        passwordInput.type = 'password';
    }
}


const SignupProForm = ({currentCountry}) => {
    const recaptcha = useRef()

    // On définit de manière dynamique le placeholder des inputs
// des téléphones (la lib utilisée ne le permet pas...)
    setTimeout(() => {
        const phoneInputContainers = document.querySelectorAll(':scope .react-tel-input');
        phoneInputContainers.forEach((container) => {
            const phoneInput = container.querySelector('input');
            const { placeholder } = phoneInput;
            const inputLabel = container.querySelector('.flag-dropdown');
            inputLabel.dataset.placeholder = placeholder;
        });
    });

    let [redirect, setRedirect] = useState(null);
    let [selected, setSelected] = useState(currentCountry.cities[0].zones);
    let [categories, setCategories] = useState([]);
    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = () => {
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            setCategories(RESPONSEDATA);
            // setSelected(currentCountry.cities[0])
          })
    }

    const dispatch = useDispatch();

    let countries = useSelector(state => state.country.countries);

    let defaultCountrySlug = currentCountry.slug;
    
    const handleVilleChange = (cityId) => {
        let data = currentCountry.cities.find(element => element.id == cityId.target.value);
        setSelected(data.zones);
    };
    const formik = useFormik({

        initialValues: {
            first_name: '',
            category_id: '',
            phone: '',
            phone_indicatif: '',
            whatsapp: '',
            whatsapp_indicatif: '',
            password: '',
            email: '',
            address: '',
            zone_id: currentCountry.cities[0].zones[0].id,
            city_id: currentCountry.cities[0].id,
            country_id: 1,
            type: 2,
            pro_rs: '',
            activity_title: '',
            activity_description: '',
            horaires: '',
            last_name: '',
            pro_rc: '',
            images: '',
            acceptTerms: false
        },

        validationSchema: Yup.object({
            first_name: Yup.string()
                .required('Ce champ est requis'),
            category_id: Yup.string()
                .required('Ce champ est requis'),
            pro_rs: Yup.string()
                .required('Ce champ est requis'),
            address: Yup.string()
                .required('Ce champ est requis'),
            city_id: Yup.string()
                .required('Ce champ est requis'),
            zone_id: Yup.string()
                .required('Ce champ est requis'),
            password: Yup.string()
                .required('Ce champ est requis'),
            phone: Yup.string()
                .min(8, "Au min 8 caracteres").required("Ce champ est requis"),
            whatsapp: Yup.string()
                .min(8, "Au min 8 caracteres"),
            acceptTerms: Yup.bool()
                .oneOf([true], 'L\'acceptation des termes et conditions sont requises'),
        }),

        onSubmit: (values, actions) => {



            const captchaValue = recaptcha.current.getValue()
            if(captchaValue){

                let userPro = {...values};
                let p = values.phone_indicatif.length;
                userPro.phone = userPro.phone.substring(p - 0);

                let w = values.whatsapp_indicatif.length;
                userPro.whatsapp = userPro.whatsapp.substring(w - 0);
                userPro.country_id = currentCountry.id;
                delete userPro.acceptTerms;

                createUser(userPro).then(({data})=> {
                    if (data.RESPONSECODE == 200) {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        //dispatch(setPreLoginData({ user : data.RESPONSEDATA }));
                        dispatch(setPreLoginData({ user : {...userPro ,...data?.RESPONSEDATA} }));

                        dispatch(changeOperation({ operation : 0 }));
                        dispatch(setOpen({open : true}));
                        formik.resetForm();
                        actions.setSubmitting(false);
                        setRedirect("/number-confirmation");
                    } else {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                        actions.setSubmitting(false);
                        dispatch(setBackColor({background: "red"}));
                    }
                }).catch((e)=>{
                    dispatch(setBackColor({background: "red"}));
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                    actions.setSubmitting(false);
                })

            }
            else {
                dispatch(setBackColor({background: "red"}));
                dispatch(setMessage({message : "Veuillez vérifier le que vous n'etes pas un robot !"}));
                dispatch(setOpen({open : true}));
                actions.setSubmitting(false);

            }


        },
    });

    if(redirect)
        return <Redirect to={redirect} />

    return (
        <form className="signup-form pro" onSubmit={formik.handleSubmit}>
            <div className="logo-font">
                <h3>Taari</h3>
            </div>
            <strong className="signup-form-title">Créer votre compte professionnel Taari</strong>
            <div className="informations">
                <strong className="signup-form-subtitle">Vos informations</strong>
                <div className="signup-form-line">
                    <div className="half-form-wrapper">
                        <TextField
                            name="first_name"
                            id="firstName"
                            label="Prénom"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                            className={"firstname" + (formik.errors.first_name && formik.touched.first_name ? ' is-invalid' : '')}
                        />
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <p className="errorMessage">{formik.errors.first_name}</p>
                        ) : null}
                    </div>
                    <div className="half-form-wrapper">
                        <FormControl className={"select-category" + (formik.errors.category_id && formik.touched.category_id ? ' is-invalid' : '')}>
                            <InputLabel id="category-label">Catégorie de votre activité</InputLabel>
                            <Select
                                name="category_id"
                                id="activityCategory"
                                labelId="category-label"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category_id}
                            >
                            {
                                categories.map(element => {
                                    return (<MenuItem value={element.id}>{element.title_fr}</MenuItem>)
                                })
                            }
                            </Select>
                        </FormControl>
                        {formik.touched.category_id && formik.errors.category_id ? (
                            <p className="errorMessage">{formik.errors.category_id}</p>
                        ) : null}
                    </div>
                </div>

                <div className="signup-form-line">
                    <div className="half-form-wrapper">
                        <TextField
                            name="pro_rs"
                            id="societyName"
                            label="Nom de la société"
                            type="text"
                            variant="outlined"
                            className={"society-name" + (formik.errors.pro_rs && formik.touched.pro_rs ? ' is-invalid' : '')}
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pro_rs}
                        />
                        {formik.touched.pro_rs && formik.errors.pro_rs ? (
                            <p className="errorMessage">{formik.errors.pro_rs}</p>
                        ) : null}
                    </div>
                    
                    <div className="half-form-wrapper">
                        <TextField
                            name="address"
                            id="address"
                            label="Adresse"
                            type="text"
                            variant="outlined"
                            className={"address" + (formik.errors.address && formik.touched.address ? ' is-invalid' : '')}
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <p className="errorMessage">{formik.errors.address}</p>
                        ) : null}
                    </div>
                </div>

                {/* <div className="signup-form-line">
                    <div className="half-form-wrapper">
                        <TextField
                            name="activity_title"
                            id="activityTitle"
                            label="Titre de votre activité"
                            type="text"
                            variant="outlined"
                            className={"activity-title" + (formik.errors.activity_title && formik.touched.activity_title ? ' is-invalid' : '')}
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.activity_title}
                        />
                        {formik.touched.activity_title && formik.errors.activity_title ? (
                            <p className="errorMessage">{formik.errors.activity_title}</p>
                        ) : null}
                    </div>
                    <div className="half-form-wrapper">
                        <TextField
                            name="activity_description"
                            id="activityDescription"
                            label="Présentation de votre activité"
                            type="text"
                            variant="outlined"
                            className={"presentation" + (formik.errors.activity_description && formik.touched.activity_description ? ' is-invalid' : '')}
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.activity_description}
                        />
                        {formik.touched.activity_description && formik.errors.activity_description ? (
                            <p className="errorMessage">{formik.errors.activity_description}</p>
                        ) : null}
                    </div>
                </div> */}

                <div className="signup-form-line">
                    <div className="half-form-wrapper">
                        <FormControl className={"select-city" + (formik.errors.city_id && formik.touched.city_id ? ' is-invalid' : '')}>
                            <InputLabel id="city-label">Ville</InputLabel>
                            <Select
                                name="city_id"
                                id="city"
                                labelId="city-label"
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    handleVilleChange(e)
                                    formik.handleChange(e)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.city_id}
                            >
                            {
                                currentCountry.cities.map(element => {
                                    return (<MenuItem value={element.id}>{element.name}</MenuItem>)
                                })
                            }
                            </Select>
                        </FormControl>
                        {formik.touched.city_id && formik.errors.city_id ? (
                            <p className="errorMessage">{formik.errors.city_id}</p>
                        ) : null}
                    </div>
                    <div className="half-form-wrapper">
                        <FormControl className={"select-district" + (formik.errors.zone_id && formik.touched.zone_id ? ' is-invalid' : '')}>
                            <InputLabel id="district-label">Quartier</InputLabel>
                            <Select
                                name="zone_id"
                                id="zone"
                                labelId="district-label"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.zone_id}
                            >
                            {
                                selected && selected.map(element => {
                                    return (<MenuItem value={element.id}>{element.name}</MenuItem>)
                                })
                            }
                            </Select>
                        </FormControl>
                        {formik.touched.zone_id && formik.errors.zone_id ? (
                            <p className="errorMessage">{formik.errors.zone_id}</p>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="account">
                <strong className="signup-form-subtitle">Votre compte</strong>

                <div className="signup-form-line">
                    <div className="half-form-wrapper">
                        <TextField
                            name="email"
                            id="email"
                            label="Adresse e-mail (facultative)"
                            variant="outlined"
                            size="small"
                            className={(formik.errors.email && formik.touched.email ? ' is-invalid' : '')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <p className="errorMessage">{formik.errors.email}</p>
                        ) : null}
                    </div>
                    <div className="half-form-wrapper">
                        <div className="password-div">
                            <TextField
                                name="password"
                                id="password"
                                label="Mot de passe"
                                type="password"
                                className={(formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                variant="outlined"
                                size="small"
                            />
                            <span className="show-password" onClick={togglePassword}><VisibilityIcon /></span>
                            {formik.touched.password && formik.errors.password ? (
                                <p className="errorMessage">{formik.errors.password}</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="signup-form-line phones">
                    <div className="half-form-wrapper tel-input">
                        <PhoneInput
                            inputProps={{id : "phoneNumber", name : "phone"}}
                            onChange={(phone, data) => {
                                formik.setFieldValue('phone', phone);
                                formik.setFieldValue('phone_indicatif', "" + data.dialCode);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            localization={fr}
                            /*regions={'africa'}*/
                            country={defaultCountrySlug}
                            searchPlaceholder={'Rechercher'}
                            countryCodeEditable={false}
                            containerClass={'phone-input' + (formik.errors.phone && formik.touched.phone ? ' is-invalid' : '')}
                            placeholder={'Numéro de téléphone'}
                            defaultMask={'.. .. .. .. .. ..'}
                            preferredCountries = {
                                countries.map((country) => (
                                    country.slug
                                ))
                            }
                            /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                            enableSearch={true}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <p className="errorMessage">{formik.errors.phone}</p>
                        ) : null}
                    </div>
                    <div className="half-form-wrapper tel-input">
                        <PhoneInput
                            inputProps={{id : "whatsappPhoneNumber", name : "whatsapp"}}
                            onChange={(phone, data) => {
                                formik.setFieldValue('whatsapp_indicatif', ""+data.dialCode);
                                formik.setFieldValue('whatsapp', phone);

                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.whatsapp}
                            localization={fr}
                            /*regions={'africa'}*/
                            country={defaultCountrySlug}
                            searchPlaceholder={'Rechercher'}
                            countryCodeEditable={false}
                            containerClass={'whatsapp-input' + (formik.errors.whatsapp && formik.touched.whatsapp ? ' is-invalid' : '')}
                            placeholder={'Numéro de téléphone Whatsapp'}
                            defaultMask={'.. .. .. .. .. ..'}
                            preferredCountries = {
                                countries.map((country) => (
                                    country.slug
                                ))
                            }
                            /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                            enableSearch={true}
                        />
                        {formik.touched.whatsapp && formik.errors.whatsapp ? (
                            <p className="errorMessage">{formik.errors.whatsapp}</p>
                        ) : null}
                    </div>
                </div>

                <div className="full-form-wrapper cgu">
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="acceptTerms"
                                name="acceptTerms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.acceptTerms}
                                color="primary"
                                className={'cgu' + (formik.errors.acceptTerms && formik.touched.acceptTerms ? ' is-invalid' : '')}
                            />
                        }
                        label={<label htmlFor="acceptTerms">J'accepte les <NavLink to="/conditions-generales">Conditions Générales d'Utilisation</NavLink></label>} />
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                        <p className="errorMessage">{formik.errors.acceptTerms}</p>
                    ) : null}
                </div>


                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RC_SITE_KEY} />


                <div className="signup-form-line last-line">
                    <div className="half-form-wrapper">
                        <NavLink to="/login">Me connecter</NavLink>
                    </div>
                    <div className="half-form-wrapper">
                        <Button type="submit" className="signup-form-button" disabled={formik.isSubmitting} variant="contained" color="primary">
                            Créer mon compte
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default SignupProForm;
