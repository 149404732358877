import { axios} from '../utils/axios'

import {
    apiUrl,
} from '../utils/axios';

export function getOnlineAdverts(data){
    return axios.post(`${apiUrl}/get_user_acount_online_adverts`, data);
}

export function getDraftAdverts(data){
    return axios.post(`${apiUrl}/get_user_acount_draft_adverts`, data);
}

export function getInProgressAdverts(data){
    return axios.post(`${apiUrl}/get_user_acount_inprogress_adverts`, data);
}

export function getRejectedAdverts(data){
    return axios.post(`${apiUrl}/get_user_acount_rejected_adverts`, data);
}

export function getDeletedAdverts(data){
    return axios.post(`${apiUrl}/get_user_acount_deleted_adverts`, data);
}

export function setSavedAdvert(data){
    return axios.post(`${apiUrl}/set_saved_advert`, data);
}

export function setSavedSearch(data){
    return axios.post(`${apiUrl}/set_saved_search`, data);
}

export function getUserSavedAdvert(data){
    return axios.post(`${apiUrl}/get_user_saved_advert`, data);
}

export function getUserSavedSearch(data){
    return axios.post(`${apiUrl}/get_user_saved_search`, data);
}

export function deleteUserSavedAdvert(data){
    return axios.post(`${apiUrl}/delete_user_saved_advert`, data);
}

export function deleteUserSavedSearch(data){
    return axios.post(`${apiUrl}/delete_user_saved_search`, data);
}