function getLSAuthData() {
    let dataString = localStorage.getItem("authData")
    if (dataString) {
        let data = JSON.parse(dataString)
        return data
    }
}

function setLSAuthData(data) {
    localStorage.setItem("authData", JSON.stringify(data))
}

function getLSAuthToken() {
    let dataString = localStorage.getItem("authToken")
    if (dataString) {
        let data = JSON.parse(dataString)
        return data
    }
}
function setLSAuthToken(data) {
    localStorage.setItem("authToken", JSON.stringify(data))
}
function removeLSAuthToken(data) {
    localStorage.removeItem("authToken")
}


export {
    setLSAuthData,
    getLSAuthData,
    setLSAuthToken,
    getLSAuthToken,
    removeLSAuthToken
}