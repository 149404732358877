import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import {MdChevronLeft, MdChevronRight, MdClose} from "react-icons/md";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

export class SimpleDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ancestorLevel: true,
            childLevel: false,
            current: undefined,
            selected: undefined
        }

        this.handleClose = this.handleClose.bind(this);
        this.close = this.close.bind(this);
        this.stepBack = this.stepBack.bind(this);
        this.stepForward = this.stepForward.bind(this);
        this.confirm = this.confirm.bind(this);
        this.selectChild = this.selectChild.bind(this);
    }

    handleClose(selected, isChild) {
        this.props.onClose(selected, this.state.current, isChild);
        this.setState((state) => ({ ancestorLevel: true, childLevel: false, current: undefined, selected: undefined }));
    }

    close() {
        this.props.onClose(undefined);
        this.setState((state) => ({ ancestorLevel: true, childLevel: false, current: undefined, selected: undefined }));
    }

    stepBack() {
        this.setState((state) => ({ ancestorLevel: true, childLevel: false, current: undefined, selected: undefined }));
    }

    stepForward(element) {
        this.setState((state) => ({ current: element, childLevel: true, ancestorLevel: false }));
    }

    selectChild(element) {
        this.setState((state) => ({ selected: element }));
    }

    // Fonction executee quand on clique sur le bouton confirmer
    confirm() {
        // On a un dialogue qui permet d'afficher plusieurs niveau de choix
        let selected = (this.state.childLevel && this.state.selected !== undefined) ? this.state.selected :
            (this.state.childLevel && this.state.current !== undefined ? this.state.selected : undefined);
        let isChild = this.state.selected ? true : false;
        this.handleClose(selected, isChild);
    }

    render() {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                className="nested-menu"
                onClose={this.close}
                aria-labelledby="simple-dialog-title"
                open={this.props.open}>
                <IconButton aria-label="close" className="closeButton" onClick={this.props.onClose}>
                    <MdClose />
                </IconButton>
                <div className="nested-menu-container">
                    {/*<CountryPopup activePanel="country"  />*/}
                    <DialogTitle id="simple-dialog-title">{this.props.prompt}</DialogTitle>
                    <DialogContent>
                       {/* {
                            this.props.isChild && this.props.isChild == true ? (
                                <div className="message-error">
                                    <p>Vous devez selectionner une sous categorie pour continuer</p>
                                </div>
                            ) : ('')
                        }*/}
                        <List>
                            <ListItem className="nested-menu-first-item">
                                {(this.state.childLevel == true) &&
                                <Button onClick={this.stepBack}>
                                    <MdChevronLeft />
                                </Button>
                                }
                                <RadioGroup aria-label="nested-menu-selected" name="nested-menu-selected" >
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={this.state.current == undefined ? this.props.defaultSelection : this.state.current.title}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                            </ListItem>

                            {
                                (this.state.childLevel == true) &&
                                this.state.current.items.map((element, index) => {
                                        return (
                                            <ListItem key={element.title+"-"+element.id} onClick={() => this.selectChild(element)} className={"nested-menu-item " + ((this.state.selected && element.title == this.state.selected.title) ? "active" : "")}>
                                                <span>{element.title}</span>
                                                {/*<MdChevronRight />*/}
                                            </ListItem>
                                        )
                                    }
                                )
                            }

                            {
                                (this.state.ancestorLevel == true) &&
                                this.props.data && this.props.data.map((element, index) => {
                                        return (<ListItem key={element.title+"-"+element.id} className="nested-menu-item" onClick={(() => this.stepForward(element))}>
                                            <div className="element-wrapper">
                                                {
                                                    this.props.hasImage === true ? (
                                                        <img src={element.image} alt={element.title} />
                                                    ) : ('')
                                                }
                                                <span>{element.title}</span>
                                            </div>
                                            <MdChevronRight />
                                        </ListItem>)
                                    }
                                )
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <div onClick={this.confirm} className="nested-menu-confirmation-button">
                            <Button>Confirmer</Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
};