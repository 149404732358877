//export const apiUrl = `https://taaridev.esdev-volontariat.org/index.php/api`;
//export const staticApiUrl = `https://taaridev.esdev-volontariat.org/uploads`;
//export const apiUrl = `https://api.esdev-volontariat.org/index.php/api`;
//export const staticApiUrl = `https://api.esdev-volontariat.org/uploads`;
import * as BaseAxios from "axios";
import { getLSAuthToken } from "./storage"; // Replace with your actual function to retrieve the token


export const apiUrl = `https://api.esdev-volontariat.org/index.php/api`;
export const staticApiUrl = `https://api.esdev-volontariat.org/public/uploads`;
//export const apiUrl = `http://localhost:8080/api`;
//export const staticApiUrl = `http://localhost:8080/uploads`;


// Create an Axios instance
const axios = BaseAxios.create({
    baseURL: apiUrl, // Replace with your base URL
    timeout: 5000, // Optional: set a timeout for requests
});

// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        // Check if the token exists and the request is not excluded
        const accessToken = getLSAuthToken();
        const shouldSkipAuth = config.headers?.skipAuth;

        if (accessToken && !shouldSkipAuth) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        // Remove the custom skipAuth property
        if (shouldSkipAuth) {
            delete config.headers.skipAuth;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);




export default {
  apiUrl,
  staticApiUrl,
  
};
export {
  axios
}
