import { axios} from '../utils/axios'
import { advertisementLoading } from '../reducers/advertisementReducer';

import {
  apiUrl,
} from '../utils/axios';

export function getDiscussions(data){
  return axios.post(`${apiUrl}/get_discussions`, data);
}

export function createDiscussion(data){
    return axios.post(`${apiUrl}/new_discussion`, data);
}

export function createMessage(data){
    return axios.post(`${apiUrl}/new_message`, data);
}

export function deleteDiscussion(data){
  return axios.post(`${apiUrl}/delete_discussion`, data);
}

export function subscribeToOneSignal(data){
  return axios.post(`${apiUrl}/user_player_id_subscription`, data);
}