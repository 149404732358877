/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';

import Loading from '../Loading';
import Country from './Country';
//import axios from "axios";
import { axios } from '../../utils/axios'
import {apiUrl} from "../../utils/axios";
import {getAllCountriesWithCities} from "../../data/countries";

const Countries = ({
  countries,
  getAllCountries,
  countrySelected,
  toggleCountry,
  countriesLoaded,
}) => {
  useEffect(() => {
    getAllCountries();
  }, []);

  const sortedCountries = countries.slice().sort((a, b) => a.order > b.order);

  return (
    <section className="homepage-countries">
      <div className="container">
        <h2 className="homepage-countries-title bold">Choisir une communauté</h2>
        { (!countriesLoaded) && (<Loading word="Chargement" />) }
        { (sortedCountries.length < 1 && countriesLoaded) && (
          <>
            <strong className="homepage-countries-subtitle italic">Aucune communauté n'est présente actuellement.</strong>
          </>
        ) }
        <ul>
          { countries.map((country) => (
            <Country
              key={uuid()}
              country={country}
              countrySelected={countrySelected}
              toggleCountry={toggleCountry}
            />
          )) }
        </ul>
      </div>
    </section>
  );
};

Countries.propTypes = {
  getAllCountries: PropTypes.func.isRequired,
};

export default Countries;
