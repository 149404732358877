import React from "react";
import './NumberConfirmation.scss';
import Header from "../../../containers/Header";
import PhoneInput from "react-phone-input-2";
import fr from 'react-phone-input-2/lang/fr.json';
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import {saveUser, setPreLoginData} from "../../../reducers/userReducer";
import {passwordInit, numberInit, codeGeneration} from '../../../services/users';
import {setBackColor, setMessage, setOpen} from "../../../reducers/snackbarReducer";
import { Redirect } from 'react-router-dom';

class NumberConfirmation extends React.Component {
    constructor(props){
        super(props);
        this.currentUser = props.currentUser;
        this.state = {
            number: props.operation == 0 || props.operation == 2 ? `${this.props.preLoginData.phone_indicatif}${this.props.preLoginData.phone}` : '',
            confirm: false,
        };
        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setBackColor = props.setBackColor;
        this.setPreLoginData = props.setPreLoginData;
        this.operation = props.operation;
    }

    componentDidMount() {
    }

    handleNumberChange = (e) => {
        this.setState({number: e});
    };

    submit = () => {
        const {number} = this.state;
        if (number == ''|| number.length < 11) {
            this.setMessage("Veuillez Saisir un numero précedé de l'indicatif");
            this.setOpen(true);
        } else {
            if (this.operation == 0) {
                var userDataNumber = {
                    user_id: this.props.preLoginData.user_id,
                    phone_indicatif : number.slice(0, 3),
                    phone : number.substring(3),
                    ...this?.props?.preLoginData
                }

                codeGeneration(userDataNumber).then(({data}) => {
                    if (data.RESPONSECODE === 200) {
                        this.setPreLoginData(userDataNumber);
                        this.setMessage(`Un code a été envoyé au +${userDataNumber.phone_indicatif} ${userDataNumber.phone}`);
                        this.setOpen(true);
                        this.setBackColor('green');
                        this.setState((state) => ({ confirm : true }));
                    } else {
                        this.setBackColor('red');
                        this.setMessage("Erreur, veuillez réessayer!");
                    }
                }).catch(error => {
                    // this.setMessage({message : data.RESPONSEMESSAGE});
                    this.setOpen(true);
                });
            }  else if (this.operation == 1)  {
                var userDataPassword = {
                    phone_indicatif : number.slice(0, 3),
                    phone : number.substring(3)
                }

                passwordInit(userDataPassword).then(({data}) => {
                    if (data.RESPONSECODE === 200) {
                        this.setPreLoginData(data.RESPONSEDATA);
                        this.setMessage(data.RESPONSEMESSAGE);
                        this.setOpen(true);
                        this.setBackColor('green');
                        this.setState((state) => ({ confirm : true }));
                    } else {
                        this.setMessage(data.RESPONSEMESSAGE);
                    }
                }).catch(error => {
                    // this.setMessage({message : data.RESPONSEMESSAGE});
                    this.setBackColor('red');
                    this.setOpen(true);
                });
            } else if (this.operation == 2) {
                var userDataNumber = {
                    user_id: this.currentUser.id,
                    phone_indicatif : number.slice(0, 3),
                    phone : number.substring(3)
                }

                numberInit(userDataNumber).then(({data}) => {
                    console.warn('numberInit: ', data);
                    if (data.RESPONSECODE === 200) {
                        this.setPreLoginData(userDataNumber);
                        // this.setMessage(data.RESPONSEMESSAGE);
                        // this.setOpen(true);
                        this.setState((state) => ({ confirm : true }));
                    } else {
                        this.setMessage(data.RESPONSEMESSAGE);
                    }
                }).catch(error => {
                    // this.setMessage({message : data.RESPONSEMESSAGE});
                    this.setOpen(true);
                });
            }
        }
    };

    render() {
        //console.log('CURENT USERRRR 2', this.currentUser)
        //console.log('PRELOAD USERRRR 2 é', this.props.preLoginData)

        return (
            <div>
                <Header/>
                {this.state.confirm && <Redirect to="/number-validation" />}
                
                <div className="number-confirmation">
                    <div className="number-confirmation-form">
                        {
                            this.operation == 1 ? (
                                <div>
                                    <h1>Réinitialisation du mot de passe</h1>
                                    <p>Indiquer votre numéro de téléphone de connexion, nous allons vous aidez !</p>
                                </div>
                            ) : (
                                <h2>Confirmez votre numéro de téléphone</h2>
                            )
                        }
                        <form action="">
                            <PhoneInput
                                inputProps={{id : "confirmation-number", name : "whatsappPhoneNumber"}}
                                localization={fr}
                                country={'tg'}
                                containerClass={"confirmation-number"}
                                placeholder={'Numéro de téléphone'}
                                defaultMask={'.. .. .. .. .. ..'}
                                preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                enableSearch={true}
                                value={this.state.number}
                                onChange={this.handleNumberChange}
                            />
                            <p className="send-message">Nous enverrons un code de vérification sur ce numéro</p>
                            <p className="send-fees">Des frais de SMS et de transfert de données peuvent s'appliquer</p>
                            <Button className="verification-button" onClick={this.submit}>M'envoyer un code de vérification</Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  preLoginData: state.user.preLoginData,
  operation: state.user.operation,
});

const mapDispatchToProps = dispatch => {
    return {
        setOpen: (open) => {
            dispatch(setOpen({ open : open}));
        },
        setMessage: (message) => {
            dispatch(setMessage({ message : message}));
        },
        setBackColor: (backColor) => {
            dispatch(setBackColor({backColor: backColor}));
        },
        setPreLoginData: (user) => {
            dispatch(setPreLoginData({ user : user}));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)( NumberConfirmation);
